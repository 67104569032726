import React from 'react'
import Layout from '../components/Layout';

export default function Index() {
  return (
    <Layout>
      <h1>404</h1>
    </Layout>
  );
}
